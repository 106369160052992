import React from 'react'

import {
	Image,
	Modal,
	VStack,
	Button,
	Heading,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalSharingHelper = ({ onClose, isOpen, item }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={item.svg_color} w="70" h="70" mb="1rem" />
						<Heading size="lg" variant="hero">
							Let's share!
						</Heading>
						<Text>
							<strong>Your message is copied</strong>, ready for you to paste in {item.label} and share
							it.
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							variant="ghost"
							onClick={() => {
								onClose()
							}}>
							Close
						</Button>
						<Button onClick={() => window.open(item.redirect)}>Continue</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalSharingHelper
