export default [
	{
		type: 'whatsapp',
		id: 'invite_whatsapp',
		url: 'https://api.whatsapp.com/send?text={text}',
		label: 'WhatsApp',
		svg_grayscale: '/assets/images/icons/socials/icon-whatsapp-white.svg',
		svg_color: '/assets/images/icons/socials/icon-whatsapp-color.svg',
		alpha: 'rgba(4,223,160,0.1)',
	},
	{
		type: 'messenger-web',
		id: 'invite_messenger_web',
		url: null,
		redirect: 'https://www.messenger.com/new?app_id=1826421140802874',
		label: 'Messenger',
		svg_grayscale: '/assets/images/icons/socials/icon-messenger-white.svg',
		svg_color: '/assets/images/icons/socials/icon-messenger-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'messenger-app',
		id: 'invite_messenger_app',
		url: 'fb-messenger://share?link={url}&app_id=1826421140802874',
		label: 'Messenger',
		svg_grayscale: '/assets/images/icons/socials/icon-messenger-white.svg',
		svg_color: '/assets/images/icons/socials/icon-messenger-color.svg',
		alpha: 'rgba(8,133,250,0.1)',
	},
	{
		type: 'slack',
		id: 'invite_slack',
		url: null,
		redirect: 'slack://open',
		label: 'Slack',
		svg_grayscale: '/assets/images/icons/socials/icon-slack-white.svg',
		svg_color: '/assets/images/icons/socials/icon-slack-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'qr',
		id: 'invite_qr',
		render: true,
		label: 'QR Code',
		svg_grayscale: '/assets/images/icons/socials/icon-qr-white.svg',
		svg_color: '/assets/images/icons/socials/icon-qr-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'linkedin',
		id: 'invite_linkedin',
		url: null,
		redirect: 'https://www.linkedin.com/messaging/thread/new/',
		label: 'Linkedin',
		svg_grayscale: '/assets/images/icons/socials/icon-linkedin-white.svg',
		svg_color: '/assets/images/icons/socials/icon-linkedin-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'facebook',
		id: 'share_facebook',
		url: 'https://www.facebook.com/dialog/share?href={url}&redirect_uri={url}&app_id=1826421140802874&display=popup',
		label: 'Facebook',
		svg_grayscale: '/assets/images/icons/socials/icon-facebook-white.svg',
		svg_color: '/assets/images/icons/socials/icon-facebook-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'gmail-web',
		id: 'share_gmail_web',
		url: 'https://mail.google.com/mail/?view=cm&to={email_address}&su={title}&body={text}',
		label: 'Gmail',
		svg_grayscale: '/assets/images/icons/socials/icon-gmail-white.svg',
		svg_color: '/assets/images/icons/socials/icon-gmail-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'gmail-app',
		id: 'share_gmail_app',
		url: 'googlegmail:///co?view=cm&to={email_address}&subject={title}&body={text}',
		label: 'Gmail',
		svg_grayscale: '/assets/images/icons/socials/icon-gmail-white.svg',
		svg_color: '/assets/images/icons/socials/icon-gmail-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'email',
		id: 'invite_email',
		url: 'mailto:{email_address}?subject={title}&body={text}',
		label: 'Email',
		svg_grayscale: '/assets/images/icons/socials/email-white.svg',
		svg_color: '/assets/images/icons/socials/email-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'sms',
		id: 'invite_sms',
		url: 'sms:{phone_number}?&body={text}',
		label: 'SMS',
		svg_grayscale: '/assets/images/icons/socials/sms-white.svg',
		svg_color: '/assets/images/icons/socials/sms-color.svg',
		alpha: 'rgba(0,102,204,0.1)',
	},
	{
		type: 'vk',
		id: 'share_vk',
		url: 'http://vk.com/share.php?url={url}&title={title}&comment={text}',
		label: 'VK',
		svg_grayscale: '/assets/images/icons/socials/icon-vk-white.svg',
		svg_color: '/assets/images/icons/socials/icon-vk-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'telegram',
		id: 'share_telegram',
		url: 'https://telegram.me/share/url?url={url}&text={title}&to={phone_number}',
		label: 'Telegram',
		svg_grayscale: '/assets/images/icons/socials/icon-telegram-white.svg',
		svg_color: '/assets/images/icons/socials/icon-telegram-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	{
		type: 'qzone',
		id: 'share_qzone',
		url: 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url={url}',
		label: 'QZone',
		svg_grayscale: '/assets/images/icons/socials/icon-qzone-white.svg',
		svg_color: '/assets/images/icons/socials/icon-qzone-color.svg',
		alpha: 'rgba(64,186,252,0.1)',
	},
	// {
	// 	type: 'twitter',
	// 	id: 'invite_twitter',
	// 	url: 'https://twitter.com/intent/tweet?url={url}&text={title}',
	// 	label: 'Twitter',
	// 	svg_grayscale: '/assets/images/icons/socials/icon-twitter-white.svg',
	// 	svg_color: '/assets/images/icons/socials/icon-twitter-color.svg',
	// 	alpha: 'rgba(3,169,244,0.1)',
	// },
	// {
	// 	type: 'copylink',
	// 	id: 'invite_copy',
	// 	url: null,
	// 	label: 'Copy Link',
	// 	svg_grayscale: '/assets/images/icons/socials/icon-copy-link-white.svg',
	// 	svg_color: '/assets/images/icons/socials/icon-copy-link-color.svg',
	// 	alpha: 'rgba(64,186,252,0.1)',
	// },
	// {
	// 	type: 'copymessage',
	// 	id: 'invite_copy_message',
	// 	url: null,
	// 	label: 'Copy Message',
	// 	svg_grayscale: '/assets/images/icons/socials/icon-copy-message-white.svg',
	// 	svg_color: '/assets/images/icons/socials/icon-copy-message-color.svg',
	// },
]
