/** Data */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { eventTypes } from '@vidday/data'
import { useEventRecipients } from './useRecipients'
import { useEventContext } from '../contexts/EventProvider'
import useInvitationMessage from './useInvitationMessage'
import useBaseUri from './useBaseUri'
import { useEventHandlersV2 } from './useEventHandlers'
import config from '../../../client/config.client'

// extends dayjs with utc and timezone plugins.
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

const useInvitationShareMessage = (shareOption) => {
	const { event } = useEventContext()
	const baseUri = useBaseUri()
	const recipients = useEventRecipients()
	const handlers = useEventHandlersV2(event.uuid)
	const { inviteMessage } = useInvitationMessage()

	let eventOccasion, link, privateLink, message, fixedMessage, title, deadline, text, email, phone, eventTimezone

	eventOccasion = eventTypes.filter((el) => el?.type == event?.occasion)[0]
	privateLink = `${config.baseUrlShort}/e/${event.uuid}?v=${event.privateKey}`
	link = shareOption === 'recipient' ? privateLink : `${baseUri}${handlers.public_root}`
	/** ACTIVE event status */
	title = `${eventOccasion?.label} video for ${recipients.formatted} - Join in on the fun!`
	eventTimezone = event?.timezone ? event?.timezone : dayjs.tz.guess()
	deadline = event?.deadline ? dayjs(event?.deadline).tz(eventTimezone).format('llll') : "it's too late"
	message = inviteMessage
	fixedMessage = `\n\nSubmit your message before ${deadline}.\n\n${link}`
	text = encodeURIComponent(`${message}${fixedMessage}`)
	email = ''
	phone = ''
	/** Published event status */
	if (event.status == 'published') {
		title = `A VidDay video for ${recipients.formatted}!`
		text = `We made an unforgettable VidDay video for ${recipients.formatted} — have a look. \n ${link}`
		if (shareOption == 'toRecipient') {
			title = `Unwrap your VidDay, ${recipients.firstname}!`
			text = `A video gift made just for you. \n ${privateLink}`
		}
		if (shareOption == 'fromRecipient') {
			title = `Check out my VidDay gift!`
			text = `I recieved this wonderful ${eventOccasion?.label} video gift and wanted to share it. \n ${link}`
		}
	}

	return { link, title, deadline, text, email, phone }
}
export default useInvitationShareMessage
