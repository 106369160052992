import React, { memo, useCallback, useRef } from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	chakra,
} from '@chakra-ui/react'
import { useEventContext } from '../../../contexts/EventProvider'
import { QRCodeCanvas } from 'qrcode.react'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { useEventOccasionLabel } from '../../../hooks/useEventOccasionLabel'

const ModalQRCodeDownload = ({ onClose, isOpen }) => {
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const occasionLabel = useEventOccasionLabel()
	const svgRef = useRef()

	const imgOpts = {
		src: '/assets/images/qr-code-template-vidday-logo-black.svg',
		height: 76,
		width: 250,
		excavate: true,
	}

	const handleDownloadQRCode = () => {
		const canvas = svgRef.current.children[0]
		const pngFile = canvas.toDataURL('image/png')

		const downloadLink = document.createElement('a')
		downloadLink.download = `VidDay-Invitation-QR-${recipients.formatted.replace(
			/[^0-9a-zA-Z-_]/,
			'_'
		)}_${occasionLabel}_${event.uuid}`
		downloadLink.id = 'invitation_qr_download'
		downloadLink.href = `${pngFile}`
		downloadLink.click()
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<chakra.div
							ref={svgRef}
							onClick={handleDownloadQRCode}
							style={{ margin: 'auto', width: '125px', height: '125px' }}>
							<QRCodeCanvas
								style={{ width: '125px', height: '125px' }}
								value={`https://vid.day/e/${event.uuid}?utm_medium=qr&utm_source=${event.uuid}`}
								level="H"
								size={600}
								bgColor="transparent"
								fgColor="#000000"
								includeMargin="true"
								imageSettings={imgOpts}
							/>
						</chakra.div>
						<Heading size="lg" variant="hero">
							Invite with a QR code.
						</Heading>
						<Text>People can scan this QR code to see their invitation and submit videos and photos.</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="space-evenly">
						<Button w="40%" variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button w="40%" onClick={handleDownloadQRCode}>
							Download
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalQRCodeDownload
