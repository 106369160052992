import React, { useState, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import AliceCarousel from 'react-alice-carousel'
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '../../atoms/Icon'
import { chakra, Box, Flex, Text, useDisclosure, Image, Icon, DarkMode, LightMode } from '@chakra-ui/react'
import { SliderArrow } from '../../atoms/SliderArrow'
import inviteMethods from './methods'
import { useEventContext } from '../../../contexts/EventProvider'
import { logCustomEvent } from '@vidday/utils'
import ModalSharingHelper from '../../organisms/ModalSharingHelper'
import useInvitationShareMessage from '../../../hooks/useInvitationShareMessage'
import { copyText } from '../../../utils/index'
import { isBrowser } from 'react-device-detect'
import ModalQRCodeDownload from '../../organisms/ModalQRCodeDownload'

const InvitationItemV2 = ({ item, onClick, theme, ...rest }) => {
	const { isOpen, onClose, onOpen } = useDisclosure()

	const shouldUseModal = item.redirect

	let themeStyles = {
		backgroundColor: theme == 'dark' ? 'transparent' : 'white',
		border: theme == 'dark' ? '1px solid' : '1px solid',
		borderColor: theme == 'dark' ? 'whiteAlpha.300' : 'transparent',
		_hover: {
			backgroundColor: theme == 'dark' ? 'whiteAlpha.100' : 'gray.200',
			cursor: 'pointer',
		},
	}

	return (
		<Flex
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			w="8rem"
			h="2.5rem"
			borderRadius="full"
			boxShadow="base"
			pr="0.45rem"
			pl="0.75rem"
			sx={themeStyles}
			as="a"
			onClick={(e) => {
				onClick(e)
				/* Open modal on click if we're using the modal */
				shouldUseModal && onOpen()
			}}
			{...rest}>
			<Text fontSize="0.875rem" fontWeight="semibold" color={theme == 'dark' ? 'white' : '#47596A'}>
				{item.label}
			</Text>
			<Flex
				boxSize={'1.5rem'}
				borderRadius="full"
				bg={theme == 'dark' ? 'rgba(255,255,255,0.32)' : item.alpha}
				justifyContent="center"
				alignItems={'center'}>
				<Image src={theme == 'dark' ? item.svg_grayscale : item.svg_color} maxW={'1rem'} />
			</Flex>
			{shouldUseModal && (
				<>
					{theme == 'dark' ? (
						<LightMode>
							<ModalSharingHelper isOpen={isOpen} onClose={onClose} item={item} />
						</LightMode>
					) : (
						<ModalSharingHelper isOpen={isOpen} onClose={onClose} item={item} />
					)}
				</>
			)}
		</Flex>
	)
}

// const InvitationItem = ({ item, onClick, theme, ...rest }) => {
// 	const { isOpen, onClose, onOpen } = useDisclosure()

// 	const shouldUseModal = item.redirect

// 	let themeStyles = {
// 		backgroundColor: theme == 'dark' ? 'transparent' : 'white',
// 		border: theme == 'dark' ? '1px solid' : '1px solid',
// 		borderColor: theme == 'dark' ? 'whiteAlpha.300' : 'transparent',
// 		_hover: {
// 			backgroundColor: theme == 'dark' ? 'whiteAlpha.100' : 'gray.200',
// 			cursor: 'pointer',
// 		},
// 	}

// 	return (
// 		<Box w="80px" h="80px" sx={themeStyles} boxShadow="base" borderRadius="base">
// 			<chakra.a
// 				textAlign="center"
// 				h="full"
// 				w="full"
// 				display="flex"
// 				flexDirection="column"
// 				justifyContent="center"
// 				onClick={(e) => {
// 					onClick(e)
// 					/* Open modal on click if we're using the modal */
// 					shouldUseModal && onOpen()
// 				}}
// 				{...rest}>
// 				<Flex mb="0.5rem" justify="center">
// 					<Image src={theme == 'dark' ? item.svg_grayscale : item.svg_color} />
// 				</Flex>
// 				<Text color={theme == 'dark' ? 'white' : 'inherit'} fontSize="0.625rem" cursor="pointer">
// 					{item.label}
// 				</Text>
// 				{shouldUseModal && <ModalSharingHelper isOpen={isOpen} onClose={onClose} item={item} />}
// 			</chakra.a>
// 		</Box>
// 	)
// }

const InvitationItemWrapper = (props) => {
	return (
		<Flex w="8rem" minW="8rem" maxW="8rem" pl=".125rem" pr="0.5rem" pt="0.5rem" pb="0.5rem">
			<InvitationItemV2 {...props} w="8rem" />
			{props.children}
		</Flex>
	)
}

/**
 * Hook that retrieves all methods according to the ones provided
 */
const useInvitationMethods = ({ exclude }) => {
	const selectableMethods = useMemo(() => {
		if (inviteMethods) {
			const methods = inviteMethods.filter((el) => {
				if (!exclude.includes(el.type) && (isBrowser ? !el.type.includes('-app') : !el.type.includes('-web'))) {
					return el
				} else return false
			})
			return methods
		} else return inviteMethods
	}, [isBrowser, exclude, inviteMethods])

	return selectableMethods
}

/** Available contexts */
// const contexts = ['recipient', 'public']

const InvitationMethods = ({
	shareOption,
	excludedDesktopOptions = [],
	excludedMobileOptions = [],
	theme,
	disableButtonsControls,
	...rest
}) => {
	const { event } = useEventContext()
	const m = useInvitationShareMessage(shareOption)
	let optsToExclude = isBrowser
		? [...excludedDesktopOptions, 'vk', 'qzone', 'telegram']
		: [...excludedMobileOptions, 'vk', 'qzone', 'telegram']

	let fade
	let color
	switch (theme) {
		case 'light':
			fade = 'linear-gradient( 90deg, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 100% )'
			break
		case 'dark':
			fade = 'linear-gradient( 90deg, rgba(11,33,57,0) 0%, rgba(11,33,57,1) 100% )'
			color = 'white'
			break
		case 'white':
			fade = 'linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100% )'
			break
		default:
			fade = 'none'
	}

	const collection = useInvitationMethods({ exclude: optsToExclude })

	const onInviteClick = (action, category, label) => {
		logCustomEvent({
			action: action,
			category: `${category}${shareOption ? `-${shareOption}` : ``}`,
			label: label,
			identifier: event.uuid,
		})
	}

	const qrModal = useDisclosure()

	const thumbItems = collection.map((el, i) => {
		if (el.render) {
			return (
				<InvitationItemWrapper
					theme={theme}
					key={i}
					item={el}
					target="_blank"
					rel="noreferrer"
					onClick={() => {
						qrModal.onOpen()
						onInviteClick('invite', 'invitations', el.type)
					}}>
					<ModalQRCodeDownload isOpen={qrModal.isOpen} onClose={qrModal.onClose} />
				</InvitationItemWrapper>
			)
		} else {
			if (el.url) {
				var elementUrl = el.url
					.replace(/{url}/g, m.link)
					.replace(/{text}/g, m.text)
					.replace(/{title}/g, m.title)
					.replace(/{email_address}/g, m.email)
					.replace(/{phone_number}/g, m.phone)

				return (
					<InvitationItemWrapper
						theme={theme}
						key={i}
						item={el}
						href={elementUrl}
						target="_blank"
						rel="noreferrer"
						onClick={() => onInviteClick('invite', 'invitations', el.type)}
					/>
				)
			} else if (el) {
				return (
					<InvitationItemWrapper
						theme={theme}
						key={i}
						item={el}
						href={elementUrl}
						target="_blank"
						rel="noreferrer"
						title={`Send with ${el.label}`}
						onClick={(e) => {
							var copied = copyText(e, el.redirect || el.type == 'copymessage' ? m.text : m.link, true)
							if (copied) {
								onInviteClick('invite', 'invitations', el.type)
							}
						}}
					/>
				)
			} else {
				return <></>
			}
		}
	})

	// Carousel ref
	let Carousel

	return (
		<Box
			id="invitation_methods"
			position="relative"
			// my="1rem"
			mx="auto"
			minH="2rem"
			w="full"
			{...rest}
			__css={{
				'.alice-carousel__stage': {
					padding: ['0 0 0 1rem', '0'],
				},
			}}
			_before={{
				pointerEvents: 'none',
				content: '""',
				width: '10px',
				height: '100%',
				marginLeft: '-3px',
				position: 'absolute',
				bottom: '0',
				zIndex: '3',
				left: '0px',
				transform: 'rotate(180deg)',
				background: fade,
			}}
			_after={{
				pointerEvents: 'none',
				content: '""',
				width: '32px',
				marginRight: '-3px',
				height: '100%',
				position: 'absolute',
				bottom: '0',
				zIndex: '0',
				right: '0px',
				background: fade,
			}}>
			{!disableButtonsControls && (
				<SliderArrow
					direction="left"
					aria-label="Previous"
					theme={theme}
					icon={<ArrowBackIosNewRounded w="1rem" h="1rem" color="link" />}
					onClick={() => Carousel.slidePrev()}
				/>
			)}
			{!disableButtonsControls && (
				<SliderArrow
					direction="right"
					aria-label="Next"
					theme={theme}
					icon={<ArrowForwardIosRounded w="1rem" h="1rem" color="link" />}
					onClick={() => Carousel.slideNext()}
				/>
			)}
			<AliceCarousel
				ref={(el) => (Carousel = el)}
				items={thumbItems}
				activeIndex={0}
				autoWidth
				// infinite
				disableDotsControls
				disableButtonsControls
				touchMoveDefaultEvents={false}
				swipeDelta={5}
				mouseTracking={false}
				touchTracking
			/>
		</Box>
	)
}

InvitationMethods.defaultProps = {
	shareOption: '',
}

InvitationMethods.propTypes = {
	shareOption: PropTypes.oneOf(['', 'toRecipient', 'fromRecipient', 'public']),
}

export default InvitationMethods
