import { eventTypes } from '@vidday/data'
import { useEventContext } from '../contexts/EventProvider'

/**
 * Retrieve occasion label using the event types
 * */
export const getOccasionLabelFromEventTypes = ({ occasion, occasionOther }, withPrefix) => {
	let result = ''
	if (occasion) {
		let occasionData = eventTypes.filter((occ) => {
			return occ.type == occasion
		})[0]
		if (withPrefix) {
			let label = occasion == 'other' ? occasionOther : occasionData.label
			result = `${occasionData.prefix} ${label}`
		} else {
			result = occasion == 'other' ? occasionOther : occasionData.label
		}
	}
	return result
}

/**
 * Retrieve the occasion label of an event and
 * fallback to default from event types
 * */
export const useEventOccasionLabel = (withPrefix) => {
	const { event } = useEventContext()
	const result = getOccasionLabelFromEventTypes(
		{ occasion: event.occasion, occasionOther: event.occasionOther },
		withPrefix
	)
	return result
}
