import React, { useState } from 'react'
import { chakra, forwardRef, Link, Text, VStack } from '@chakra-ui/react'
import { handleCopy } from '@vidday/utils'
import { logCustomEvent } from '@vidday/utils'
import useBaseUri from '../../../hooks/useBaseUri'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { ContentCopyRounded } from '../../atoms/Icon'
import config from '../../../../../client/config.client'

/**
 * Copy Link component
 * @param {string} label
 * @param {boolean} toRecipient
 * @returns
 */

const CopyLink = ({ label, event, toRecipient, labelColor, hideIcon, linkColor, forwardRef, as = 'p', ...rest }) => {
	const [clicked, setClicked] = useState(false)

	const handleCopyLink = (e, link) => {
		handleCopy(e, link)
		setClicked(true)
		setTimeout(() => {
			setClicked(false)
		}, 3000)
	}

	const handlers = useEventHandlersV2(event.uuid)
	const baseUri = useBaseUri()

	return (
		<VStack as="span" spacing="0" {...rest}>
			{label && (
				<Text as={as} color={labelColor}>
					{label}
				</Text>
			)}
			{!clicked && (
				<Link
					ref={forwardRef}
					display="inline"
					color={linkColor}
					onClick={(e) => {
						e && e.preventDefault()
						logCustomEvent({
							action: 'invite',
							category: 'invitations',
							label: 'copylink',
							identifier: event.uuid,
						})
						handleCopyLink(
							e,
							toRecipient
								? `${config.baseUrlShort}/e/${event.uuid}?v=${event.privateKey}`
								: `${baseUri}${handlers.public_root}`
						)
					}}>
					<chakra.strong
						sx={{
							display: 'inline',
							userSelect: 'all',
							cursor: 'pointer',
						}}>
						{toRecipient
							? `${config.baseUrlShort}/e/${event.uuid}?v=${event.privateKey}`
							: `${baseUri}${handlers.public_root}`}
						{!hideIcon && <ContentCopyRounded ml="0.25rem" position="relative" w="20px" h="20px" />}
					</chakra.strong>
				</Link>
			)}

			{clicked && (
				<Text as={as} p="0" m="0" d="inline-flex" color={linkColor}>
					<strong>Copied to clipboard!</strong>
				</Text>
			)}
		</VStack>
	)
}

CopyLink.defaultProps = {
	labelColor: 'inherit',
	linkColor: 'var(--vidday-colors-link)',
}

export default CopyLink
